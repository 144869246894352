<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="title">订单信息</h5>
          <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
        </div>
        <ul class="listBox">
          <li><span>结算订单号</span><i>{{detail.mchSettleOrderNo?detail.mchSettleOrderNo:' '}}</i></li>
          <li><span>结算流水号</span><i>{{detail.plfSettleOrderNo?detail.plfSettleOrderNo:' '}}</i></li>
          <li><span>三方结算订单号</span><i>{{detail.upsSettleOrderNo?detail.upsSettleOrderNo:' '}}</i></li>
          <li><span>产品类型</span><i>{{productCode[detail.productCode]}}</i></li>
          <li><span>分账方名称</span><i>
            <Tooltip
              effect="light"
              style="height: 20px"
              :content="detail.allocMchName"
              placement="top-start">
              {{detail.allocMchName}}
            </Tooltip>
          </i></li>
          <li><span>分账方编号</span><i>{{detail.allocMchId?detail.allocMchId:' '}}</i></li>
          <li><span>创建时间</span><i>{{detail.createTime?detail.createTime:' '}}</i></li>
          <li><span>交易时间</span><i>{{detail.tradeTime?detail.tradeTime:' '}}</i></li>
          <li><span>结算账户卡号</span><i>{{detail.accountNo?detail.accountNo:' '}}</i></li>
          <li><span>结算账户名称</span><i>{{detail.accountName?detail.accountName:' '}}</i></li>
          <li><span>结算金额</span><i>{{detail.settleAmount/100|formatMoney}}</i></li>
          <li><span>到账金额</span><i>{{detail.remitAmount/100|formatMoney}}</i></li>
          <li><span>平台手续费</span><i>{{detail.settleFee/100 | formatMoney}}</i></li>
          <li><span>分账方手续费</span><i>{{detail.allocMchSettleFee/100|formatMoney}}</i></li>
          <li><span>交易状态</span><i>{{orderStatus[detail.orderStatus]}}</i></li>
          <li><span>错误描述</span><i class="hidden1">
            <Tooltip
              effect="light"
              style="height: 20px"
              :content="detail.resultMessage ? detail.resultMessage : detail.errorMessage"
              placement="top-start">
              {{detail.resultMessage ? detail.resultMessage : detail.errorMessage}}
            </Tooltip>
          </i></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
	import {getSettleordersDetail} from "@/api/basic/balance.js";
  export default {
    data() {
      return {
				detail:'',
				notices:[],
        crumbs: "交易详情",
				orderStatus:{
					PROCESSING:'处理中',
					FAIL:'结算失败',
					SUCCESS:'结算成功'
				},
				notifyType:{
					PLATFORM:'平台',
					THIRD:'第三方'
				},
				notifyStatus:{
					SUCCESS:'成功',
					FAIL:'失败'
				},
				productCode:{
					// 2:'分账方-手工结算(可结算余额结算)',
					// 3:'分账方-D0 手工结算(当日收款金额结算)'
          2:'分账方-手工结算(可用余额结算)',
					3:'分账方-D0 手工结算(当日收款金额结算)'
				}
      }
    },
		created() {
			this.getDetail()
		},
    methods: {
			// 获取详情
			getDetail(){
				getSettleordersDetail(this.$route.query.id).then(res=>{
					if(res){
						this.detail = res.resultData.detail
						this.notices = res.resultData.notices
					}
				})
			},
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
    }
  }
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
</style>
